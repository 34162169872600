





















































import get from 'lodash/get'
import BnglInput from '@/components/shared/bngl-input.vue'

export default {
  name: 'structure-form',
  props: ['value'],
  components: {
    'bngl-input': BnglInput,
  },
  data() {
    return {
      structure: { ...this.value },
    }
  },
  methods: {
    onNameChange(name) {
      if (this.geometry) {
        const geomStruct = this.geometry.structures.find((struct) => struct.name === name)

        if (geomStruct) {
          this.structure.size = geomStruct.size.toPrecision(5)
        }
      }

      this.onStructureChange()
    },
    onStructureChange() {
      this.structure.valid = this.isValid()
      this.$emit('input', this.structure)
    },
    isValid() {
      return !!this.structure.name.trim()
    },
    onSizeInputTab() {
      this.$refs.annotationInput.focus()
    },
    focus() {
      this.$refs.nameInput.focus()
    },
    refresh() {
      this.$refs.sizeInput.refresh()
    },
  },
  computed: {
    // TODO: filter available parent structures according to BNGL rules
    parentStructureNames() {
      return this.$store.state.model.structures.filter((s) => s.name !== this.structure.name).map((s) => s.name)
    },
    geometryStructureNames() {
      if (!this.geometry) return []

      return this.geometry.meta.structures.map((structure) => structure.name)
    },
    isRootStructurePresent() {
      return this.$store.state.model.structures.some((s) => s.parentName === '-')
    },
    geometry() {
      return get(this.$store.state, 'model.geometry')
    },
  },
  watch: {
    value() {
      this.structure = { ...this.value }
    },
  },
}
