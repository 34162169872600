






























import isEqualBy from '@/tools/is-equal-by'

import StructureForm from '@/components/shared/entities/structure-form.vue'
import { PUBLIC_USER_ID } from '@/constants'

export default {
  name: 'structure-properties',
  components: {
    'structure-form': StructureForm,
  },
  props: ['value', 'error'],
  data() {
    return {
      modified: this.value,
      original: this.value,
    }
  },
  mounted() {
    this.focusStructureForm()
  },
  methods: {
    focusStructureForm() {
      this.$nextTick(() => this.$refs.structureForm.focus())
    },
    onInput() {
      this.$emit('input', this.modified)
    },
    applyStructureChange() {
      this.$emit('apply')
    },
    resetStructureChange() {
      this.modified = this.original
    },
  },
  computed: {
    structureEdited() {
      return !isEqualBy(this.modified, this.original, [
        'name',
        'type',
        'parentName',
        'size',
        'annotation',
        'geometryStructureName',
      ])
    },
    isPublicModel() {
      return this.$store.state.model?.user_id === PUBLIC_USER_ID
    },
  },
  watch: {
    value(val, oldVal) {
      if (val.id !== oldVal.id) {
        this.modifiedParam = val
        this.oParam = val
        this.focusNameInput()
      }
    },
  },
}
